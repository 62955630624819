import { TextInput, Box, Text } from '@mantine/core';
import StampPart from './StampPart';
import { useState, useRef, FormEvent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useVerifyMutation } from '../api';
import Stamp from './Stamp';

export default function VerifyStamp() {
    const [file, setFile] = useState<File>();
    const [tab, setTab] = useState<string | null>('text');
    const formRef = useRef<HTMLFormElement>(null);
    const captchaRef = useRef<ReCAPTCHA>(null);
    const [verify, {isLoading, error, data }] = useVerifyMutation();
    function handleSubmit(e : FormEvent) {
        e.preventDefault();
        if (!isLoading) {
            const data = new FormData(formRef.current!);
            data.set('input-type', tab!);
            if (file) data.set('file', file);
            verify(data);
            captchaRef.current?.reset();
        }
    }
    let result;
    if (data) {
        if (data.errorMessage) result = <Text color="red">{data.errorMessage}</Text>;
        else result = <Stamp {...data}/>;
    }
    return (
        <>
            <Box mt={5} component="form" ref={formRef} onSubmit={handleSubmit}>
                <TextInput label="Stamp ID" withAsterisk mb={12} name="id"/>
                <StampPart webpage={false} submitLabel="Verify" file={file} setFile={setFile} tab={tab} setTab={setTab} captchaRef={captchaRef} loading={isLoading}/>
            </Box>
            <Box mx="auto" sx={{width: '95%', wordWrap: 'break-word'}} mt={10}>
                {!!error && <Text color="red">An error occurred.</Text>}
                {result}
            </Box>
        </>
    );
}