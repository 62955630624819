import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState, useAppSelector } from '../store';
export interface RecentStamp {
    id : number
    utcTime : string
}
export interface SignVerifyResponse {
    id : number;
	owner : boolean;
	comment : string;
	checkSum : string;
	checkSumType : string;
	checkSumClearSignature : string;
	checkSumClearSignatureCheckSum : string;
	detachedSignature : string;
	detachedSignatureCheckSum : string;
	previousPrimaryCheckSum : string;
	previousExtendedCheckSum : string;
	utcTime : string;
	resultPrimaryCheckSum : string;
	resultExtendedCheckSum : string;
	clearTextSignature : string;
	urlRequestId : number | null;
	errorMessage : string;
	matches : boolean | null;
    sourceType: string;
}

export interface StampsResponse {
    stamps : SignVerifyResponse[]
    pages : number
}

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL, 
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.token;
            if (token) headers.set('Authorization', 'Bearer ' + token);
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        getRecentStamps: builder.query<RecentStamp[], void>({query : () => '/recentstamps'}),
        sign: builder.mutation<SignVerifyResponse, FormData>({query: body => ({url: '/sign', body, method: 'POST'})}),
        verify: builder.mutation<SignVerifyResponse, FormData>({query: body => ({url : '/verify', body, method: 'POST'})}),
        getStamp: builder.query<SignVerifyResponse, string>({query: id => `/stamp/${id}`}),
        getStamps: builder.query<StampsResponse, {page: number, basePath: string}>({query: ({page, basePath}) => `${basePath}/${page}`}),
        search: builder.query<SignVerifyResponse[], URLSearchParams>({query: params => '/search?' + params}),
    })
});
export const { useGetRecentStampsQuery, useSignMutation, useVerifyMutation, useGetStampQuery, useGetStampsQuery, useLazySearchQuery } = api;

interface ApiFetchOptions extends RequestInit {
    token ?: string
    throwOnHTTPError ?: boolean
    json ?: boolean
}
export function apiFetch(url : string, {token, throwOnHTTPError = true, json = true, headers, ...rest} : ApiFetchOptions = {}) {
    return fetch(process.env.REACT_APP_API_BASE_URL + url, {
        headers: {...headers, ...token && {'Authorization' : 'Bearer ' + token}},
        credentials: 'include',
        ...rest
    }).then(res => {
        if (!res.ok && throwOnHTTPError) throw Error(res.statusText);
        return json ? res.json() : res;
    });
}

export function useToken() {
    return useAppSelector(state => state.user.token);
}
