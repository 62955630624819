import { Box, Title, Select, Group, TextInput, Center, Loader, Text, Divider } from '@mantine/core';
import { IconChevronDown, IconSearch, IconAlertCircle } from '@tabler/icons';
import { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { useLazySearchQuery, useToken } from '../api';
import Stamp from '../components/Stamp';

export default function SearchStamps() {
    const token = useToken();
    const [searchBy, setSearchBy] = useState<string | null>("ID");
    const [query, setQuery] = useState('');
    const [triggerSearch, { data, isLoading, error, isUninitialized }] = useLazySearchQuery();
    function handleSubmit(e: SyntheticEvent) {
        e.preventDefault();
        if (!isLoading) {
            triggerSearch(new URLSearchParams({[searchBy!.toLowerCase()] : query}));
        }
    }
    return <>
        <Box sx={theme => ({
            background: theme.fn.linearGradient(190, theme.colors.cyan[1], theme.colors.cyan[0]), 
            padding: '30px 10%'
        })}>
            <Title color="dark.4">Search Stamps</Title>
        </Box>
        <form style={{maxWidth: '80%', margin: '30px auto 10px auto'}} onSubmit={handleSubmit}>
            <Group spacing={0}>
                <Select value={searchBy} onChange={setSearchBy} data={['ID', 'Checksum', ...(token && [{label: 'Comment Containing', value: 'comment'}]) || []]} rightSection={<IconChevronDown size={18}/>} rightSectionWidth={30} styles={{ rightSection: { pointerEvents: 'none' } }} radius={0} size="md"/>
                <TextInput value={query} onChange={e => setQuery(e.currentTarget.value)} placeholder="Query..." sx={{flexGrow: 1}} rightSection={<IconSearch size={18} onClick={handleSubmit}/>} radius={0} size="md"/>
            </Group>
            <button type="submit" style={{display: 'none'}}></button> {/* required for automatic submission on enter */}
        </form>
        {!isUninitialized && (isLoading ? <Center><Loader/></Center> : error ? <Text color="red" ta="center"><IconAlertCircle color="red" size={16}/> Could not fetch search results.</Text> : 
        <><Divider my="lg" size="md"></Divider>
        <Box mx="auto" maw="80%" mb={5}>
            <Title order={3}>Results</Title>
            {!data || !data.length ? "No results." : 
                data.map(stamp => <Stamp key={stamp.id} {...stamp}/>)
            }
        </Box>
        </>)}
    </>;
}