import { apiFetch } from "../api";
export async function downloadFile(path : string, token ?: string) {
    const res = await apiFetch(path, {token, json : false}) as Response;
    const a = document.createElement('a');
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    a.href = url;
    const contentDisposition = res.headers.get("Content-Disposition");
    if (contentDisposition) {
        const filename = contentDisposition.split("filename=")[1];
        if (filename) a.download = filename.slice(1, -1);
    }
    a.click();
    URL.revokeObjectURL(url);
}
