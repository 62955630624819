import { IconCertificate } from '@tabler/icons';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { SignVerifyResponse, apiFetch, useToken } from '../api';
import StampPart from './StampPart';
import { Text, Box, Textarea } from '@mantine/core';
import Stamp from './Stamp';
import ReCAPTCHA from 'react-google-recaptcha';

interface FetchDetails {
    data ?: SignVerifyResponse
    error ?: string
    loading ?: boolean
}

export default function StampDocument() {
    const [file, setFile] = useState<File>();
    const [tab, setTab] = useState<string | null>('text');
    const formRef = useRef<HTMLFormElement>(null);
    const [{data, error, loading}, setStatus] = useState<FetchDetails>({});
    const poll = useRef<boolean>();
    const token = useToken();
    const captchaRef = useRef<ReCAPTCHA>(null);
    useEffect(() => () => {poll.current = false}, []);
    async function urlPolling(id : number) {
        try {
            const res = await apiFetch('/urlresponse/' + id, {token}) as SignVerifyResponse;
            if (res.id && poll.current) {
                if (res.errorMessage) {
                    setStatus({error: res.errorMessage});
                } else {
                    setStatus({data: res});
                }
                return;
            }
        } catch (e) {}
        if (poll.current) setTimeout(() => urlPolling(id), 5000);
    }
    function handleSubmit(e : FormEvent) {
        e.preventDefault();
        if (!loading) {
            setStatus({loading : true});
            const data = new FormData(formRef.current!);
            data.set('input-type', tab!);
            if (file) data.set('file', file);
            apiFetch('/sign', {method: 'POST', token, body: data}).then(res => {
                if (res.errorMessage) setStatus({error: res.errorMessage});
                else if(res.urlRequestId) {
                    poll.current = true;
                    urlPolling(res.urlRequestId);
                } else {
                    setStatus({data: res});
                }
            }).catch(e => {
                setStatus({error: 'An error occurred.'});
            });
            captchaRef.current?.reset();
        }
    }
    return <><form onSubmit={handleSubmit} ref={formRef}>
        <StampPart webpage={true} submitLabel="Sign" submitIcon={<IconCertificate/>} file={file} setFile={setFile} loading={loading} tab={tab} setTab={setTab} captchaRef={captchaRef}>
            {!!token && <Textarea label="Additional comments (only visible to you)" mt={5} name="comment" variant="filled"></Textarea>}
        </StampPart>
        </form>
        <Box mx="auto" sx={{width: '95%', wordWrap: 'break-word'}} mt={10}>
            {!!error && <Text color="red">{error}</Text>}
            {data && <Stamp {...data}/>}
        </Box>
    </>;
}
