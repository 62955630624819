import {
    createStyles,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    Anchor,
    Card,
    Grid,
    Tabs,
    Divider
  } from '@mantine/core';
import { IconScale, IconRubberStamp, IconZoomCode, IconChevronsRight } from '@tabler/icons';
import { Dots } from '../components/Dots';
import RecentStampsTable from '../components/RecentStampsTable';
import { RefObject, useRef } from 'react';
import StampDocument from '../components/StampDocument';
import VerifyStamp from '../components/VerifyStamp';
import About from '../components/About';
import ShareButtons from '../components/ShareButtons';
  
  const useStyles = createStyles((theme) => ({
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing.xl * 4,
      paddingBottom: theme.spacing.xl * 2,
    },
  
    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 36,
      lineHeight: 1.2,
      fontWeight: 900,
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: 28,
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        flex: 1,
      },
    },

    dots: {
        position: 'absolute',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        zIndex: -1,
        '@media (max-width: 755px)': {
            display: 'none',
        },
    },
  
    image: {
      flex: 1,
  
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    highlight: {
      position: 'relative',
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      borderRadius: theme.radius.sm,
      padding: '4px 12px',
    },
  }));
  
  export default function Home() {
    const { classes } = useStyles();
    const stampVerifyContainer = useRef<HTMLDivElement>(null);
    const aboutContainer = useRef<HTMLDivElement>(null);
    const scrollToEl = (elRef : RefObject<HTMLElement>) => window.scrollTo({top: elRef.current!.offsetTop - 25, behavior: 'smooth'});
    return (
        <div style={{position: 'relative'}}>
            <Container size={1100}>
                <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
                <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
                <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
                <Grid className={classes.inner} columns={10}>
                    <Grid.Col sm={6} xs={10}>
                    <Title className={classes.title}>
                    <IconScale/> The <span className={classes.highlight}>reliable, secure</span> {' '}
                        online {' '}
                        <Anchor color="green" href="https://en.wikipedia.org/wiki/Trusted_timestamping" rel="noopener noreferrer" target="_blank">
                            trusted timestamping</Anchor> {' '}
                        service. <IconScale/>
                    </Title>
                    <Text mt="md">
                        If you want to <Text span italic underline fw={600}>prove</Text> in the future, that...
                    </Text>
                    <List
                        mt={15}
                        spacing="sm"
                        size="sm"
                        icon={
                        <ThemeIcon size={20} radius="xl">
                            <IconChevronsRight size={12} stroke={1.5} />
                        </ThemeIcon>
                        }>
                        <List.Item>
                        you had an idea today
                        </List.Item>
                        <List.Item>
                        you wrote a paper today
                        </List.Item>
                        <List.Item>
                        a webpage contained certain content at this moment
                        </List.Item>
                    </List>

                    <Text mt={10} size={25} sx={{ fontFamily: "'Lobster Two', cursive" }} ta="center"
                    fw={700}>Then, stamp it!</Text>
        
                    <Group mt={20} position="center">
                        <Button color="cyan" size="md" className={classes.control} onClick={() => scrollToEl(stampVerifyContainer)}>
                        Get Started
                        </Button>
                        <Button color="cyan" size="md" className={classes.control} onClick={() => scrollToEl(aboutContainer)}>
                        See How It Works
                        </Button>
                    </Group>
                    </Grid.Col>
                    <Grid.Col span="auto">
                        <RecentStampsTable/>
                        <ShareButtons url={window.location.origin} title={document.title}/>
                    </Grid.Col>
                </Grid>
                <Card withBorder p="sm" radius="md" shadow="sm" ref={stampVerifyContainer}>
                    <Tabs defaultValue="stamp">
                        <Tabs.List grow>
                            <Tabs.Tab value="stamp" icon={<IconRubberStamp size={14}/>}>Stamp Document</Tabs.Tab>
                            <Tabs.Tab value="verify" icon={<IconZoomCode size={14}/>}>Verify Stamp</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="stamp">
                            <StampDocument/>
                        </Tabs.Panel>
                        <Tabs.Panel value="verify">
                            <VerifyStamp/>
                        </Tabs.Panel>
                    </Tabs>
                </Card>
                <Divider size="sm" my={50} variant="dotted"/>
                <About ref={aboutContainer} />
            </Container>
        </div>
    );
  }