import { useRef, useState } from 'react';
import { Text, Group, Button, createStyles, Checkbox, Tooltip, Center, Progress } from '@mantine/core';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { IconCloudUpload, IconX, IconDownload, IconInfoCircle, IconFileDescription } from '@tabler/icons';
import CryptoJS from 'crypto-js';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}));

function arrayBufferToWordArray(ab : ArrayBuffer) : CryptoJS.lib.WordArray {
	const i8a = new Uint8Array(ab);
	const a = [];
	for (let i = 0; i < i8a.length; i += 4) {
		a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
	}
	return CryptoJS.lib.WordArray.create(a, i8a.length);
}

export interface FileSelectProps {
  file ?: File
  onSelectFile ?: (file: File, sha ?: string) => void
}

export default function FileSelect({file, onSelectFile} : FileSelectProps) {
  const { classes, theme } = useStyles();
  const openRef = useRef<() => void>(null);
  const useSHACheckbox = useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState<number>();
  async function getSHA(blob : Blob) : Promise<string> {
    const hasher = CryptoJS.algo.SHA512.create();
    setProgress(0);
    const chunkSize = 1024 * 1024;
    for (let offset = 0; offset < blob.size; offset += chunkSize) {
      hasher.update(arrayBufferToWordArray(await blob.slice(offset, offset + chunkSize).arrayBuffer()));
      setProgress(Math.min(offset / blob.size, 1) * 100);
    }
    setProgress(undefined);
    return hasher.finalize().toString();
  }
  async function handleDrop(files : FileWithPath[]) {
    if (files.length !== 1) {
      console.warn('Drop with ' + files.length + ' files.');
    } else {
      const [file] = files;
      console.log(file);
      onSelectFile?.(file, undefined);
      let sha;
      if (useSHACheckbox.current?.checked) sha = await getSHA(file);
      onSelectFile?.(file, sha);
    }
  }

  return (
    <>
        <div className={classes.wrapper}>
        <Dropzone
            openRef={openRef}
            onDrop={handleDrop}
            className={classes.dropzone}
            radius="md"
            multiple={false}
            name="file"
        >
            <div style={{ pointerEvents: 'none' }}>
            <Group position="center">
                <Dropzone.Accept>
                <IconDownload size={50} color={theme.colors[theme.primaryColor][6]} stroke={1.5} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                <IconX size={50} color={theme.colors.red[6]} stroke={1.5} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                <IconCloudUpload
                    size={50}
                    color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black}
                    stroke={1.5}
                />
                </Dropzone.Idle>
            </Group>

            <Text align="center" weight={700} size="lg" mt="xl">
                <Dropzone.Accept>Drop file here</Dropzone.Accept>
                <Dropzone.Idle>Upload file</Dropzone.Idle>
            </Text>
            <Text align="center" size="sm" mt="xs" color="dimmed">
                {file ? <>
                    <Center><IconFileDescription size={15}/> <span style={{marginLeft: 2}}>{file.name}</span></Center> 
                    {progress !== undefined && <Progress size="lg" value={progress} animate sx={{width: '80%', margin: '0 auto'}}/>}
                  </>
                : "Drag and drop any file here or click to open file chooser."}
            </Text>
            </div>
        </Dropzone>

        <Button className={classes.control} size="md" radius="xl" onClick={() => openRef.current?.()}>
            Select file
        </Button>
        </div>
        <Checkbox label={<>Use SHA512 of file as 
            text <Tooltip inline label={<>Calculate the SHA512 checksum of the file and use it as text to sign.<br/> Recommended for large files. (Check this <i>before</i> uploading a file.)</>} withArrow>
            <span><IconInfoCircle size={16}/></span>
            </Tooltip></>} ref={useSHACheckbox}/>
    </>
  );
}