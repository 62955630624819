import { upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Checkbox,
  Anchor,
  Stack,
} from '@mantine/core';
import { FacebookButton, GithubButton, GoogleButton } from './SocialButtons';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../store/user';
import { useAppSelector } from '../store';

const AuthFormTypes = ['log in', 'register'] as const;
type AuthFormType = typeof AuthFormTypes[number];
interface AuthFormProps extends PaperProps {
  type : AuthFormType
}

function centeredPopup(url : string, windowName : string, win : Window, w : number, h : number) {
  const y = win.top!.outerHeight / 2 + win.top!.screenY - h / 2;
  const x = win.top!.outerWidth / 2 + win.top!.screenX - w / 2;
  return win.open(url, windowName, 'width=' + w + ', height=' + h + ', top=' + y +', left=' + x);
}

export default function AuthForm({type, ...rest}: AuthFormProps) {
  const initialValues = {
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    terms: false,
  };
  const form = useForm({
    initialValues,
    validate: type === 'register' ? {
      email: (val) => /^\S+@\S+.\S+$/.test(val) ? val.length > 90 ? 'Email must be at most 90 characters.' : null : 'Invalid email.',
      username: val => val.length < 6 || val.length > 40 ? 'Username must be between 6 and 40 characters in length' : !/[a-z0-9]+/.test(val) ? 'Only alphanumeric characters are allowed.' : null,
      password: (val) => (val.length < 6 || val.length > 40 ? 'Password must be between 6 and 40 characters in length.' : null),
      confirmPassword: (val, values) => val !== values.password ? 'Password and confirm password do not match.' : null,
      terms: val => val ? null : 'Please accept the terms of use.',
    } : undefined,
  });
  const dispatch = useDispatch();
  const prevOpened = useRef<Window | null>();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useAppSelector(state => state.user.token);
  function closePopup() {
    prevOpened.current?.close();
  }
  function doLogin(obj : any) {
    dispatch(login(obj));
    navigate(location.state?.prev || '/');
  }
  useEffect(() => {
    window.addEventListener("beforeunload", closePopup);
    function handleMessage(e : MessageEvent){
      if (e.origin === window.location.origin && e.data?.type === 'oauth2') {
        console.log(e);
        doLogin(e.data);
      }
    }
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("beforeunload", closePopup);
      window.removeEventListener("message", handleMessage);
    }
  }, []);
  if (token) return <Navigate to="/"/>;
  function oauth2Login(provider : string) {
    closePopup();
    prevOpened.current = centeredPopup(process.env.REACT_APP_OAUTH2_BASE_URL + '/' + provider, '_blank', window, 660, 480);
  }
  function handleSubmit(values : typeof initialValues) {
    const params = new URLSearchParams(values as unknown as Record<string, string>);
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: params
    };
    if (type === 'register') {
      fetch(process.env.REACT_APP_API_BASE_URL! + '/register', options).then(res => res.json())
        .then(data => {
          console.log(data);
          if (data.errors) {
            form.setErrors(Object.fromEntries(Object.entries(data.errors)
              .map(([path, errors]) => [path, (errors as string[]).map((error, i) => <>{!!i && <br/>}{error}</>)])));
          } else {
            doLogin(data.value);
          }
        }).catch(e => console.error(e));
    } else {
      fetch(process.env.REACT_APP_API_BASE_URL! + '/authenticate', options).then(res => {
        console.log(res.status);
        if (res.ok) {
          return res.json().then(data => doLogin(data));
        } else {
          form.setFieldError('password', 'Bad credentials.');
        }
      }).catch(e => console.error(e));
    }
  }

  return (
    <Paper radius="md" p="xl" withBorder {...rest}>
      <Text size="lg" weight={500}>
        {upperFirst(type)} with
      </Text>

      <Group grow mb="md" mt="md">
        <GoogleButton radius="xl" onClick={() => oauth2Login('google')}>Google</GoogleButton>
        <FacebookButton radius="xl" onClick={() => oauth2Login('facebook')}>Facebook</FacebookButton>
        <GithubButton radius="xl" onClick={() => oauth2Login('github')}>Github</GithubButton>
      </Group>

      <Divider label="Or" labelPosition="center" my="lg" />

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput
              label="Username"
              placeholder="Your username"
              {...form.getInputProps('username')}
            />

          {type === 'register' && <TextInput
            label="Email"
            placeholder="example@gmail.com"
            {...form.getInputProps('email')}
          /> }

          <PasswordInput
            label="Password"
            placeholder="Your password"
            {...form.getInputProps('password')}
          />

          {type === 'register' && <>
            <PasswordInput label="Confirm Password" placeholder="Confirm your password"
              {...form.getInputProps('confirmPassword')}/>
            <Checkbox label={<>I accept the <Anchor component={Link} to="/terms">terms and conditions</Anchor>.</>}
              {...form.getInputProps('terms', {type: 'checkbox', withError: true})}/>
          </>}
        </Stack>

        <Group position="apart" mt="xl">
          <Anchor
            component={Link}
            color="dimmed"
            size="xs"
            to={type === 'register' ? '/login' : '/register'}
          >
            {type === 'register'
              ? 'Already have an account? Log in'
              : "Don't have an account? Register"}.
          </Anchor>
          <Button type="submit">{upperFirst(type)}</Button>
        </Group>
      </form>
    </Paper>
  );
}