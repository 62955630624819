import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate } from 'react-router-dom';
import Home from './views/Home';
import NotFound from './views/NotFound';
import FAQ from './views/FAQ';
import DownloadStamps from './views/DownloadStamps';
import Stamps from './views/Stamps';
import LogIn from './views/LogIn';
import Register from './views/Register';
import GeneralErrorPage from './views/GeneralErrorPage';
import SearchStamps from './views/SearchStamps';
import { Provider } from 'react-redux';
import store from './store';
import OAuth2Handler from './views/OAuth2Handler';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import StampView from './views/StampView';
import UserStamps from './views/UserStamps';
import TermsOfUse from './views/TermsOfUse';
import PrivacyPolicy from './views/PrivacyPolicy';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const persistor = persistStore(store);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App/>} errorElement={<GeneralErrorPage/>}>
        <Route errorElement={<GeneralErrorPage/>}>
          <Route path="" element={<Home/>}/>
          <Route path="/faq" element={<FAQ/>}/>
          <Route path="/download" element={<DownloadStamps/>}/>
          <Route path="/stamps" element={<Navigate to="/stamps/1" replace/>}/>
          <Route path="/stamps/:page" element={<Stamps/>}/>
          <Route path="/mystamps" element={<Navigate to="/mystamps/1" replace/>}/>
          <Route path="/mystamps/:page" element={<UserStamps/>}/>
          <Route path="/stamp/:id" element={<StampView/>}/>
          <Route path="/login" element={<LogIn/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/search" element={<SearchStamps/>}/>
          <Route path="/terms" element={<TermsOfUse/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Route>
      </Route>
      <Route path="/oauth2" element={<OAuth2Handler/>} errorElement={<GeneralErrorPage/>}/>
    </>
  )
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
