import { useState } from 'react';
import { createStyles, Table, ScrollArea, Title, Flex, Image, Anchor, Loader, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import RSSIcon from '../assets/RSSFeedIcon.png';
import { useGetRecentStampsQuery } from '../api';
import { IconAlertCircle } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

export default function RecentStampsTable() {
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const { data, error, isLoading } = useGetRecentStampsQuery();

  return (
    <>
      <Flex justify="space-between" mb={5}>
        <Anchor component={Link} to="/stamps/1" color="dark"><Title order={3}>Recent Stamps</Title></Anchor>
        <a href="/api/rss">
          <Image src={RSSIcon} height={30} width={30} title="RSS Feed"/>
        </a>
      </Flex>
      {isLoading ? <Loader/> : error ? <Text color="red"><IconAlertCircle color="red" size={16}/> Could not load recent stamps!</Text> :
      <ScrollArea sx={{ height: 300 }} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
        <Table>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>
              <th>ID</th>
              <th>UTC Time</th>
            </tr>
          </thead>
          <tbody>
            {!!data && data.map((row) => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>{row.utcTime}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>}
    </>
  );
}