import { Loader, Center } from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import NotFound from "./NotFound";

export default function OAuth2Handler() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'), image = searchParams.get('image');
  const possibleOAuth = window.opener?.location?.origin === window.location.origin;
  if (possibleOAuth) window.opener.postMessage({type: 'oauth2', token, image}, window.location.origin);
  window.close();
  return possibleOAuth ? <Center my={20}><Loader/></Center> : <NotFound/>;
}
