import { Title, Text, Code, createStyles, Anchor, Box } from '@mantine/core';
import { forwardRef } from 'react';
import PrimaryChecksumCalculationDiagram from '../assets/PrimaryChecksumCalculationDiagram.png';
import PrimaryChecksumCalculator from './PrimaryChecksumCalculator';
import { IconExternalLink } from '@tabler/icons';

const useStyles = createStyles(() => ({
    inheritFont: {
        fontSize: 'inherit'
    }
}));

export default forwardRef<HTMLDivElement>(function About(props, ref) {
    const { classes } = useStyles();
    return (
        <Box ref={ref} sx={{wordWrap : 'break-word', '& pre' : {whiteSpace: 'pre-wrap'}}}>
            <Title order={2}>How does signing work?</Title>
            <Text>There are three types of stamps. The process for each is explained below.</Text>
            <Title order={3}>Text</Title>
            When text is submitted:
            <ul>
				<li>Let <Code className={classes.inheritFont}>s0</Code> be the checksum of the submitted text.</li>
				<li>Let <Code className={classes.inheritFont}>sa</Code> be the GPG clear signature of <Code className={classes.inheritFont}>s0</Code>.</li>
				<li>Let <Code className={classes.inheritFont}>sb</Code> be the GPG detached signature of the submitted text.</li>
			</ul>
			<span >The creator of the stamp can additionally download the clear text signature of the submitted text.</span>
			<h3 >File</h3>
			<span >When a file is uploaded</span>:
			<ul>
				<li>Let <Code className={classes.inheritFont}>s0</Code> be the checksum of the uploaded file.</li>
				<li>Let <Code className={classes.inheritFont}>sa</Code> be the GPG clear signature of <Code className={classes.inheritFont}>s0</Code>.</li>
				<li>Let <Code className={classes.inheritFont}>sb</Code> be the GPG detached signature of the uploaded file.</li>
			</ul>
			<h3 >URL</h3>
			<p >When a request to stamp a URL is made, a screenshot is taken of the entire page and a ZIP is created containing the source code and all resource files.	Another ZIP is created containing the screenshot and zip, and one file for each of the checksum and detached signature for the aforementioned two files.</p>
			Content.zip:
			<ul>
				<li>page.zip
					<ul>
						<li>resources
							<ul>
								<li>resource0</li>
								<li>resource1</li>
								<li>...</li>
								<li>resourceN</li>
							</ul>
						</li>
						<li>index.html</li>
					</ul>
				</li>
				<li>page.zip.asc</li>
				<li>page.zip.sha256</li>
				<li>screenshot.png</li>
				<li>screenshot.png.asc</li>
				<li>screenshot.png.sha256</li>
			</ul>
			Then:
			<ul>
				<li >Let <Code className={classes.inheritFont}>s0</Code> be the checksum of the final ZIP.</li>
				<li >Let <Code className={classes.inheritFont}>sa</Code> be the GPG clear signature of <Code className={classes.inheritFont}>s0</Code>.</li>
				<li >Let <Code className={classes.inheritFont}>sb</Code> be the GPG detached signature of the final ZIP.</li>
			</ul>
			<span >The one who created the request can download the final ZIP.</span>
			<h2 >Final Calculation</h2>
			<img src={PrimaryChecksumCalculationDiagram} alt="Primary Checksum Calculation" style={{width:'100%', maxWidth:'869px'}}/>
			<br/>
			<span >The <span style={{backgroundColor: '#ffff00'}}>primary checksum</span> for this stamp is the checksum of the concatenation of the id of this stamp, the current UTC time, <code>s0</code>, and the primary checksum for the previous stamp, with commas as delimiters between the strings. <br /> The <span style={{backgroundColor: '#ffff00'}}>extended checksum</span> for this stamp is the checksum of the concatenation of the id of this stamp, the current UTC time, <code>s0</code>, the checksum of <code>sa</code>, the checksum of <code>sb</code>, and the extended checksum for the previous stamp, with commas as delimiters between the strings. This is the checksum used for verifying the stamp.</span>
			<h2 >Example</h2>
			<ol>
				<li >The text <Code className={classes.inheritFont}>12345</Code> is entered.</li>
				<li >Assume that the previous extended checksum is <Code className={classes.inheritFont}>267b35bb6b38c77c2ab1acea205373f7a5879237545029f9c2ea23a61a19a97c</Code> and the previous primary checksum is <Code className={classes.inheritFont}>15b363d5a173a1478444c21d8b109e3011fe22ae068cc93cd26c598cd6b7cb58</Code>.</li>
				<li >Assume that this is the 40th stamp, i.e. the id of the stamp is <Code className={classes.inheritFont}>40</Code>.</li>
				<li >Assume that the UTC time is <Code className={classes.inheritFont}>2021-08-24T18:10:34.812Z</Code>.</li>
				<li >The SHA-256 of the entered text (<Code className={classes.inheritFont}>s0</Code>) is then <Code className={classes.inheritFont}>5994471abb01112afcc18159f6cc74b4f511b99806da59b3caf5a9c173cacfc5</Code>. This can be verified in shell script with the command <Code className={classes.inheritFont}>printf '%s' '12345' | sha256sum</Code>.</li>
				<li><span>The GPG clear signature of the original text, denoted by <Code className={classes.inheritFont}>sa</Code>, is:</span>
					<pre>{
`-----BEGIN PGP SIGNED MESSAGE-----
Hash: SHA256

5994471abb01112afcc18159f6cc74b4f511b99806da59b3caf5a9c173cacfc5
-----BEGIN PGP SIGNATURE-----
Version: BCPG v1.69

iQG1BAEBCAAfBQJhJTYaGBxBbGljZSA8YWxpY2VAZW1haWwuY29tPgAKCRCR8iuj
J1Z9tcluDACdHmqKJyZ4/fixfTtWfnTK6qlrRvAsQnm26goGsluZ/hcHO9IZe9Rl
YC2muqvts9nD/lgGo2y2xzhpxfyJzPGXAApNYVF0O3yoKncVyUskiZJh5cIFo1Q4
ulJRp1XMfm+NbWXcEhupXiSPqxtAdCAklWrqWmIIaJ+yhtcYM9vq+Sv04gnKFSF7
PcN+x/2XqAfuyLFD3fWV2s02QYXt9cnNdW3f/AWAnxhLDEtw1E/6lD50a3KTmnRN
sP7ts5XtD3ipqBz3Fyi8J8MSBZSvVTyrj7GFS8zLLU7LrUTsez5mSmoGwnh8DP+i
pCjQGov5FmX9z9MI8ZezEmyE1ZlKGEXLvzxYH4vh57xgHnHGm8xPPD/DCO/Gfc7t
iG9raynzTSkNR73i1k5ZhSmbLyDggqPpDuAX8Z46lh3d2Ey7ZBhVSiSfpAam4CS6
KUdi5bz+k+3pqrtBamYj2ItlIJuXkssGSSM+UhrZoVwdlUHslPqttWEqseGxQGAd
hT4t5K6zmr4=
=8WB7
-----END PGP SIGNATURE-----`}
</pre>
				</li>
				<li>The SHA-256 of <Code className={classes.inheritFont}>sa</Code> is <Code className={classes.inheritFont}>a88bac90592226f62a5d3b94a2b0e98c364cc14a397b02fc98e1ea8e880ec52e</Code>.</li>
				<li><span >The detached signature of the text (denoted as <Code className={classes.inheritFont}>sb</Code>) is, for example:</span>
					<pre>
{`-----BEGIN PGP SIGNATURE-----
Version: BCPG v1.69

iQGcBAABCAAGBQJhJTYaAAoJEJHyK6MnVn21S3oMAI0iMQtEnCDFy4xhwjV+jmt6
3M5gUKVHqYGf4pH6T8gvMcn65I0idgvIZTwPWeXXyLLjijBmYTjtmyONKmcozi2c
c5PXy+1G5D/yIqa67JWS0UlqNljt1KKoJFyqOZ44JkXyW/aLw0YyeSQOtMosIyCS
llaJpGEwUzSlx6qboi7YKrjZgDF13Izh1r91eTlrBsasw27LH3kvHKMQlvr6ChzF
ixZq5o7EZkiT7MyaX8QRLRzoVJJoPgt+i7mVBT5fmTPb5owdGlz+Z6nKk6Dsi3oF
dhTVM8JgX8mQhuKIeqa9QD2yXKd9D52fuyla2bRV590/Ad3rhUkY33pz3XJ9Bvv0
5c2IpuCKBH22vZ2Ja6d72yqQw9EQHbZVR3cfk3LkZj2k0M1GYLrK81Od/SAH5lyZ
Ke+CAuw2OB3cMgK8b6OyHUU2/BD0IL3vA/ODC9Uavd19C6lNowID4mVz9PbGlCFn
4Q+qqMfookogNW1xqZEbHW1Od5QpIpy02ZffSiqeeg==
=F19d
-----END PGP SIGNATURE-----`}
</pre>
				</li>
				<li >The SHA-256 of <Code className={classes.inheritFont}>sb</Code> is <Code className={classes.inheritFont}>0fee272561f21a32bb412c4c721c9d64206406c0c7fc92bb91a90d33def6faf5</Code>.</li>
				<li ><Code className={classes.inheritFont}>id,UTC time,SHA-256 of text,SHA-256 of sa,SHA-256 of sb,previous extended checksum</Code> = <Code className={classes.inheritFont}>40,2021-08-24T18:10:34.812Z,5994471abb01112afcc18159f6cc74b4f511b99806da59b3caf5a9c173cacfc5,a88bac90592226f62a5d3b94a2b0e98c364cc14a397b02fc98e1ea8e880ec52e,0fee272561f21a32bb412c4c721c9d64206406c0c7fc92bb91a90d33def6faf5,267b35bb6b38c77c2ab1acea205373f7a5879237545029f9c2ea23a61a19a97c</Code></li>
				<li >The SHA-256 of the above value is <Code className={classes.inheritFont}>9afe628bee044019b6db705d61c9279dded910525c956e06574ad6ad1a7e9396</Code>, which is the extended checksum for this stamp.</li>
				<li ><Code className={classes.inheritFont}>id,UTC time,SHA-256 of text,previous primary checksum</Code> = <Code className={classes.inheritFont}>40,2021-08-24T18:10:34.812Z,5994471abb01112afcc18159f6cc74b4f511b99806da59b3caf5a9c173cacfc5,15b363d5a173a1478444c21d8b109e3011fe22ae068cc93cd26c598cd6b7cb58</Code>.</li>
				<li >The SHA-256 of the above value (the primary checksum for this stamp) is: <Code className={classes.inheritFont}>b2d75da36a26376c2f42cd87017bdce9bfabe1e69168d346397c98e45a4c560b</Code></li>
			</ol>
			<PrimaryChecksumCalculator/>
			<h2 >Downloading Files</h2>
			<span >For all types of stamps, anyone can download the checksum (<Code className={classes.inheritFont}>s0</Code>),	the clear signature of the checksum (<Code className={classes.inheritFont}>sa</Code>), and the detached	signature (<Code className={classes.inheritFont}>sb</Code>). Certain other files can only be downloaded by the one who created the stamp. For instance, the owner can download the comment that they wrote for the record (if present). If the one who created the record is not logged in, it is important to note that the owner-only files will only be available for about 30 minutes after the stamp is created.</span>
			<h2>Can site administrators add stamps with dates in the past?</h2>
			<span >Site administrators cannot insert stamps between two existing stamps without detection, as each stamp relies on its previous stamp. If a new stamp is inserted before another stamp, the stamp after it will fail verification. <br/> In addition, every UTC hour, an automatic stamp is created, so the history cannot be changed past that point. Thus, a stamp can have its time set at most 59 minutes before its actual time. <br/> It is theoretically possible for a site administrator to insert a stamp and then change all the stamps after it to match. However, the <Anchor href="/api/rss">RSS feed</Anchor> (which can be subscribed to) is updated every 10 minutes and the public <Anchor href="https://github.com/DocumentStamper/DocStamps" target="_blank" rel="noreferrer">Github repository of stamps <IconExternalLink size={13}/></Anchor> is pushed to every 15 minutes, so those who are interested can immediately tell if the history was altered.</span>
			<h2>Additional Details</h2>
			<p>The current checksum algorithm used is <Anchor href="https://en.wikipedia.org/wiki/SHA-2" target="_blank" rel="noreferrer">SHA-512 <IconExternalLink size={13}/></Anchor>.</p>
        </Box>
    );
});
