import { Anchor, Box, Title } from '@mantine/core';
import { IconQuestionCircle } from '@tabler/icons';
import { Prism } from '@mantine/prism';
import { IconExternalLink } from '@tabler/icons';

export default function FAQ() {
    return <>
        <Box sx={theme => ({
            background: theme.fn.linearGradient(190, theme.colors.cyan[1], theme.colors.cyan[0]), 
            padding: '30px 10%'
        })}>
            <Title color="dark.4"><IconQuestionCircle/> Frequently Asked Questions</Title>
        </Box>
		<div style={{maxWidth: '80%', margin: '0 auto'}}>
			<h3>What is this for?</h3>
			<p>If, in the future, you need to prove that you are in possession of a file or an idea today, you can stamp
				it.
				<br /><br />
				If you are being cyberbullied and you need to provide evidence to authorities, you can stamp a webpage
				(including the screenshot and the source code of the site).</p>
			<h3>What is this not?</h3>
			<p>This is not a notary. You are anonymous&#8212;even if you create an account, we don't verify your identity.
				<br /><br />
				We can only timestamp your document or a webpage. It only proves the fact that the state of such
				material existed at a certain time.
				<br /><br />
				As such, if you want to include your copyright notice, you should include your name in the material.
				Alternatively, for files such as images, you can upload a zip containing the file and a text file with
				your copyright, or put the checksum of the image into the text field and add your copyright underneath.
			</p>
			<h3>Is there a cost to use this service?</h3>
			<p>The intention of this service is to help people, and as such it is absolutely free. We can not say this
				service will last forever, but we have no plans to charge fees for the foreseeable future (at least
				10 years).</p>
			<h3>Will this service last?</h3>
			<p>Yes, it will. This service is self running. We can afford the maintenance for this website before we
				retire :) (we are still very young).
				<br /><br />
				We have a sophisticated monitoring mechanism which will alert us should any problems occur.</p>
			<h3>Are there any privacy issues? Can the content of a document I timestamped be leaked to someone else?</h3>
			<p>We understand your concern, and the system is designed to avoid the possibliity.
				<br /><br />
				We don't store your file or text content normally (there are exceptions which will be explained later).
				<br /><br />
				When you upload a file for stamping, we creating a temporary file for a very short period. In theory, we
				can see the content of that file before it is deleted, but in reality, we have our own business, and we
				are not interested in looking at your file. We are also not responsible should your file content be
				leaked.
				<br /><br />
				If you are uploading a file with sensitive information, we strongly recommend you calculate the checksum
				of the file or content to hide the content but preserve evidence. This is because a <a
					href="https://en.wikipedia.org/wiki/Cryptographic_hash_function" target="_blank" rel="noreferrer">cryptographic hash
					(or checksum)</a> is an irreversible algorithm that produces a different result for different
				content, but
				produces the same result for the same content. You can do this yourself (for text content entered into
				the textarea) or use our tool when uploading (for files) found by clicking the 'Upload' button, then
				checking the 'Use SHA256 of file as text' checkbox on the bottom left corner.</p>
			<h3>How can I trust you? Will you change your system clock and time stamp a document to prove you had an
				idea two years ago?</h3>
			<p>The possibility has been considered, and the system has been designed to avoid it.
				<br /><br />
				Each stamp has an <code>id</code>, which starts at <code>1</code>.
				<br /><br />
				Each stamp has two hash values; the primary hash and extended hash. The primary hash is the checksum of
				the concatenation of the id of this stamp, the current UTC time, checksum of the content, and the
				primary checksum for the previous stamp, with commas as delimiters between the strings. The extended
				checksum is the checksum of the concatenation of the id of this stamp, the current UTC time, the
				checksum of the content, the checksum of the GPG clear signature of the checksum of the content, the
				checksum of the GPG detached signature of the content, and the extended checksum for the previous stamp,
				with commas as delimiters between the strings. This is the checksum used for verifying the stamp.
				<br /><br />
				As you can see, each stamp's checksum is dependent on the previous stamp's checksum. This ensures that
				it is impossible to insert a record in between stamps.</p>
			<h3>But what if there are no timestamps for a while? Can you fake the time of a stamp to be a few day ago?</h3>
			<p>Every hour a stamp is automatically created. As such, in a worst case scenario, a stamp can be backdated
				at most 59 minutes.</p>
			<h3>Would you be able to rewrite the history?</h3>
			<p>We cannot do so without detection. We publish every new stamp in our RSS feed. Furthermore, each stamp's
				checksum, clear signature and detached signature is checked into our <Anchor href="https://github.com/DocumentStamper/DocStamps" target="_blank" rel="noreferrer">Github repository <IconExternalLink size={13}/></Anchor>. Anyone can
				clone and pull it.</p>
			<h3>How can I verify the integrity of the timestamps?</h3>
			<p>Timestamps within a certain date range can be downloaded as a CSV file. You can query timestamps by Stamp
				ID, Checksum and by comments (the latter only works for stamps whom you own), all of which you can
				download.
				<br/>
				We provide an auditing tool to audit the records. If a timestamp's checksum is incorrect, the program
				will notify you of an error.</p>
			Furthermore, our <Anchor href="http://keyserver.ubuntu.com/pks/lookup?search=428F95E0DCC7AAD6A59410C019935E94E160C490&fingerprint=on&op=index" target="_blank" rel="noreferrer">GPG public key <IconExternalLink size={13}/></Anchor> can be imported with the following command:
			<Prism language="bash">gpg --keyserver keyserver.ubuntu.com --recv-keys 428F95E0DCC7AAD6A59410C019935E94E160C490</Prism>
			Verifying any signatures can be done by running:
			<Prism language="bash">gpg --verify file.asc</Prism>
			<h3>Limitations</h3>
			<p>
				Webpage material that can only be viewed by logging in cannot be captured.
			</p>
		</div>
    </>;
}