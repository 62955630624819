import { Box, Title, Anchor } from "@mantine/core";
import { Link } from "react-router-dom";

export default function TermsOfUse() {
    return <Box mx="auto" w="80%" mt="lg" mb="md">
        <Title mt={40} mb={35}>Terms of Use</Title>
        <p>
            <strong>By accessing www.docstamper.com, you acknowledge these terms of use and agree to be bound by them. If you do not agree to these terms, please do not use the site. We reserve the right, in our sole discretion, to modify, alter or otherwise update these terms of use at any time and you agree to be bound by such changes.</strong>
        </p>
        <Title order={2}>Access to the Site</Title>
        <p><b>Subject to these Terms.</b> Document Stamper grants you a non-transferable, non-exclusive, revocable, limited license to access the Site.</p>
        <p><b>Certain Restrictions.</b> The rights approved to you in these Terms are subject to the following restrictions: (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated. Any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site must be retained on all copies thereof.</p>
        <Title order={2}>Third-Party Links</Title>
        <p>The Site may contain links to third-party websites and services. Such Third-Party Links are not under the control of Document Stamper, and Document Stamper is not responsible for any Third-Party Links. Document Stamper provides access to these Third-Party Links only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links. You use all Third-Party Links at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links, the applicable third party's terms and policies apply, including the third party's privacy and data gathering practices.</p>
        <Title order={2}>Other Users</Title>
        <p>Each Site user is solely responsible for any and all of its own User Content.  Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others.  You agree that Document Stamper will not be responsible for any loss or damage incurred as the result of any such interactions.  If there is a dispute between you and any Site user, we are under no obligation to become involved.</p>
        <Title order={2}>Cookies and Web Beacons</Title>
        <p>Like any other website, www.docstamper.com uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
        <Title order={2}>Limitation on Liability</Title>
        <p>To the maximum extent permitted by law, in no event shall Document Stamper be liable to you or any third-party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or incapability to use the site even if Document Stamper has been advised of the possibility of such damages.  Access to and use of the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.

        Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
        <Title order={2}>Privacy Policy</Title>
        <p>Document Stamper respects your right to privacy. The treatment of information collected by Document Stamper is governed by our <Anchor component={Link} to="/privacy-policy">Privacy Policy</Anchor>, which you consent and agree to by your use of the Site.</p>
    </Box>;
}