import { useGetStampQuery } from "../api";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import { Box, Center, Loader, Text } from "@mantine/core";
import Stamp from "../components/Stamp";
import { IconAlertCircle } from "@tabler/icons";
import { useTitle } from "../hooks";

export default function StampView() {
    const { id } = useParams();
    const { data, isLoading, error } = useGetStampQuery(String(id));
    useTitle("Document Stamper Stamp #" + id);
    if (!id || !/^\d+$/.test(id) || +id < 1) return <NotFound/>;
    return <Box mx="auto" w="80%" mt={50}>
        { isLoading ? <Center><Loader/></Center> : error ? <Text color="red" ta="center"><IconAlertCircle color="red" size={16}/> Could not load stamp data.</Text> : !!data && <Stamp {...data} withShareButtons />}
    </Box>;
}
