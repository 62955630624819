import { Navigate, useLocation, useParams } from 'react-router-dom';
import NotFound from './NotFound';
import StampsList from '../components/StampsList';
import { useToken } from '../api';

export default function UserStamps() {
    const { page = "1" } = useParams();
    const token = useToken();
    const location = useLocation();
    if (!token) return <Navigate to="/login" replace state={{prev : location}}/>;
    if (!/^\d+$/.test(page) || +page < 1) return <NotFound/>;
    return <StampsList basePath="/mystamps" page={+page} title="Your Stamps" />;
}
