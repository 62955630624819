import { useNavigate } from 'react-router-dom';
import { Table, Box, Title, Pagination, Loader, Text, Center, Anchor } from '@mantine/core';
import { useGetStampsQuery } from '../api';
import { IconAlertCircle } from '@tabler/icons';
import { Link } from 'react-router-dom';

export interface StampsListProps {
    basePath : string;
    page : number;
    title ?: string;
}

export default function StampsList({basePath, page, title = "Stamps List"} : StampsListProps) {
    const navigate = useNavigate();
    const { data, isLoading, error } = useGetStampsQuery({basePath, page});
    return <>
        <Box
            sx={(theme) => ({
            background: theme.fn.linearGradient(
                190,
                theme.colors.cyan[1],
                theme.colors.cyan[0]
            ),
            padding: "30px 10%",
            })}
        >
            <Title color="dark.4">{title}</Title>
        </Box>
        <Table highlightOnHover withBorder withColumnBorders mb={15}>
            <thead>
                <tr>
                    <th>Stamp Number</th>
                    <th>UTC Time</th>
                    <th>Checksum</th>
                    <th>Final Extended Checksum</th>
                </tr>
            </thead>
            <tbody style={{overflowWrap: 'anywhere'}}>
                {!!data && data.stamps.map(stamp => (
                        <tr key={stamp.id}>
                            <td><Anchor component={Link} to={"/stamp/" + stamp.id}>{stamp.id}</Anchor></td>
                            <td>{stamp.utcTime}</td>
                            <td>{stamp.checkSum}</td>
                            <td>{stamp.resultExtendedCheckSum}</td>
                        </tr>
                ))}
            </tbody>
        </Table>
        {isLoading ? <Center><Loader/></Center> : error ? <Text color="red" ta="center"><IconAlertCircle color="red" size={16}/> Could not load stamps!</Text> :
        !!data && <Pagination total={data.pages} withEdges siblings={3} boundaries={2} initialPage={page} position="center" 
            onChange={nextPage => navigate(basePath + '/' + nextPage)} mb={15}/>}
    </>;
}
