import { SignVerifyResponse, useToken } from "../api";
import { Button, Group, Title, Badge, Tooltip, Box, createStyles } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons";
import ShareButtons from "./ShareButtons";
import { downloadFile } from "../utils";

interface StampProps extends SignVerifyResponse {
    withShareButtons ?: boolean
}

const useStyles = createStyles(theme => ({
    download: {
        fontSize: '0.85em',
        verticalAlign: 'top',
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#0000ee'
    }
}));

export default function Stamp(props : StampProps) {
    const { classes } = useStyles();
    const token = useToken();
    function doDownload(type: string) {
        downloadFile(`/download/${props.id}?type=${type}`, token);
    }
    return <Box sx={{'& pre': {whiteSpace: 'pre-wrap'}, wordWrap: 'break-word'}}>
        <Group position="apart" mb={5}>
            <Title><span style={{verticalAlign: 'middle'}}>Stamp Info </span>{props.matches != null && <Badge color={props.matches ? 'green' : 'red'} size="xl" radius="sm" variant="filled">{props.matches ? 'MATCH' : 'NO MATCH'}</Badge>}</Title>
            <Group>
                {props.sourceType === 'URL' && props.owner && <Button onClick={() => doDownload('pzip')}>Download Page ZIP</Button>}
                <Button onClick={() => doDownload('all')}>Download All</Button>
            </Group>
        </Group>
        {!!props.withShareButtons && <ShareButtons/>}
        <Title order={4} mt={5}>Stamp Number</Title>
        <pre>{props.id}</pre>
        {props.owner && !!props.comment && <><Title order={4}>Comment <span className={classes.download} onClick={() => doDownload('com')}>Download</span></Title> <pre>{props.comment}</pre></>}
        <Title order={4}>UTC Time</Title> 
        <pre>{props.utcTime}</pre>
        {props.owner && !!props.clearTextSignature && <>
            <Title order={4}>Clear Text Signature <span className={classes.download} onClick={() => doDownload('csm')}>Download</span></Title> <pre>{props.clearTextSignature}</pre>
        </>}
        <Title order={4}>Checksum <span className={classes.download} onClick={() => doDownload('s0')}>Download</span></Title>
        <pre>{props.checkSum}</pre>
        <Title order={4}>Clear signature of checksum <span className={classes.download} onClick={() => doDownload('sga')}>Download</span></Title>
        <pre>{props.checkSumClearSignature}</pre>
        <Title order={4}>Checksum of clear signature of checksum</Title>
        <pre>{props.checkSumClearSignatureCheckSum}</pre>
        <Title order={4}>Detached signature <span className={classes.download} onClick={() => doDownload('sgb')}>Download</span></Title>
        <pre>{props.detachedSignature}</pre>
        <Title order={4}>Checksum of detached signature</Title>
        <pre>{props.detachedSignatureCheckSum}</pre>
        <Title order={4}>Previous primary checksum</Title>
        <pre>{props.previousPrimaryCheckSum}</pre>
        <Title order={4}>Previous extended checksum</Title>
        <pre>{props.previousExtendedCheckSum}</pre>
        <Title order={4}>Final primary checksum <Tooltip inline withArrow label="Calculated by performing a hash on the Primary Key, UTC Time, Checksum and previous Primary Checksum."><span><IconInfoCircle size={16}/></span></Tooltip></Title>
        <pre>{props.resultPrimaryCheckSum}</pre>
        <Title order={4}>Final extended checksum <Tooltip inline withArrow label={<>Calculated by performing a hash on the Primary Key, UTC Time, checksum of clear signature of checksum,<br/> checksum of detached signature and previous Extended Checksum.</>}><span><IconInfoCircle size={16}/></span></Tooltip></Title>
        <pre>{props.resultExtendedCheckSum}</pre>
    </Box>;
}