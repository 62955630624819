import { Text, Group } from "@mantine/core";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
interface ShareButtonsProps {
  url ?: string;
  title?: string;
}
export default function ShareButtons({ url = window.location.href, title = document.title }: ShareButtonsProps) {
  return (
    <>
      <Text mb={5}>Share this!</Text>
      <Group spacing={7}>
        <FacebookShareButton url={url}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <RedditShareButton url={url} title={title}>
          <RedditIcon size={32} round />
        </RedditShareButton>
        <EmailShareButton url={url} subject={title}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </Group>
    </>
  );
}
