import sha256 from "crypto-js/sha256";
import {
  Button,
  Textarea,
  TextInput,
  Group,
  Center,
  Title,
} from "@mantine/core";
import { useRef, useState } from "react";

function sha256Hex(str : string) {
    return sha256(str).toString();
}

export default function PrimaryChecksumCalculator() {
  const idInput = useRef<HTMLInputElement>(null), textInput = useRef<HTMLTextAreaElement>(null), 
        previousChecksumInput = useRef<HTMLInputElement>(null);
  const [time, setTime] = useState(new Date().toISOString());
  const [result, setResult] = useState('');
  function calculate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setResult(sha256Hex(idInput.current!.value + ',' + time + ',' + sha256Hex(textInput.current!.value)
                + ',' + previousChecksumInput.current!.value));
  }
  return (
    <>
      <Title order={2} ta="center" mb="sm">
        Try the Primary Checksum Calculation
      </Title>
      <Center>
        <form style={{ maxWidth: 350, width: '95%' }} onSubmit={calculate}>
          <TextInput label="Id" ref={idInput}/>
          <Textarea label="Text" mt="sm" ref={textInput}/>
          <TextInput label="UTC Time" mt="sm" value={time} onChange={e => setTime(e.target.value)}/>
          <TextInput label="Previous primary checksum" mt="sm" ref={previousChecksumInput} />
          <Group position="right" mt="sm">
            <Button type="submit">Go!</Button>
          </Group>
        </form>
      </Center>
      {result && <Center mt="sm"><pre>{result}</pre></Center>}
    </>
  );
}
