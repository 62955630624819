import { Tabs, Textarea, Badge, TextInput, Button, Group } from '@mantine/core';
import { useState, useCallback } from 'react';
import FileSelect from './FileSelect';
import ReCAPTCHA from "react-google-recaptcha";

interface StampPartProps {
    webpage : boolean
    submitLabel : string
    submitIcon ?: React.ReactNode
    file ?: File
    setFile ?: (file ?: File) => void
    loading ?: boolean
    tab ?: string | null
    setTab ?: (tab : string | null) => void
    captchaRef ?: React.LegacyRef<ReCAPTCHA>
    children ?: React.ReactNode
}

export default function StampPart({webpage, submitLabel, submitIcon, setFile, file, loading, tab, setTab, captchaRef, children} : StampPartProps) {
    const [disabled, setDisabled] = useState(true);
    const [text, setText] = useState('');
    function handleSelectFile(file : File, sha ?: string) {
        if (sha) {
            setText(sha);
            setTab?.('text');
            setFile?.(undefined);
        } else {
            setFile?.(file);
        }
    }
    return (
        <>
            <Tabs orientation="vertical" variant="pills" color="gray" value={tab} onTabChange={setTab} mt={5}>
                <Tabs.List mr="sm">
                    <Tabs.Tab value="text">Text</Tabs.Tab>
                    <Tabs.Tab value="file">File</Tabs.Tab>
                    {webpage && <Tabs.Tab value="url">Webpage <Badge color="yellow">Beta</Badge></Tabs.Tab>}
                </Tabs.List>
                <Tabs.Panel value="text">
                    <Textarea placeholder="Enter text here..." mt={5} minRows={5} variant="filled" 
                        value={text} onChange={e => setText(e.target.value)} name="text"/>
                </Tabs.Panel>
                <Tabs.Panel value="file">
                    <FileSelect file={file} onSelectFile={handleSelectFile}/>
                </Tabs.Panel>
                {webpage && 
                <Tabs.Panel value="url">
                    <TextInput label="Webpage URL:" placeholder="https://www.example.com" name="url"/>
                </Tabs.Panel>}
            </Tabs>
            {children}
            <Group mt={10} position="center">
                <ReCAPTCHA ref={captchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                    onChange={useCallback((val : string | null) => setDisabled(!val), [])} />
                <Button size="lg" sx={{height: 'auto', minHeight: 45, alignSelf: 'stretch'}} 
                    leftIcon={submitIcon} variant="light" disabled={disabled} color="teal" type="submit" loading={loading}>
                    {submitLabel}
                </Button>
            </Group>
        </>
    );
}