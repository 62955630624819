import { createSlice } from '@reduxjs/toolkit';
const userSlice = createSlice({
  name: "user",
  initialState: {
    token: undefined, image : undefined
  },
  reducers: {
    login(state, { payload : { token, image } }) {
      state.token = token;
      state.image = image;
    },
    logout(state) {
      state.token = state.image = undefined;
    },
  },
});
export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
