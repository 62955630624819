import {
  createStyles,
  Header,
  Group,
  Button,
  Text,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  useMantineColorScheme,
  ActionIcon,
  Menu,
  Avatar,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { NavLink, Link } from 'react-router-dom';
import { IconSun, IconMoonStars, IconChevronDown, IconLogout, IconArchive } from '@tabler/icons';
import DSLogo from "../DS.png";
import jwt_decode from 'jwt-decode';
import { useAppSelector, useAppDispatch } from '../store';
import { logout } from '../store/user';
import { useState } from 'react';
  
const navBreakpoint = 845;

const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan(navBreakpoint)]: {
      height: 42,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),

    '&.active': {
      fontWeight: 'bold',
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3],
    },
  },

  dropdownFooter: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan(navBreakpoint)]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan(navBreakpoint)]: {
      display: 'none',
    },
  },

  userSection: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  userSectionActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  }
}));


export default function NavHeader() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const { classes, theme, cx } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';
  const { token, image } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const doLogout = () => dispatch(logout());
  const [userSectionOpened, setUserSectionOpened] = useState(false);
  console.log('token', token);
  let name : string;
  if (token) {
    try {
      const decoded = jwt_decode(token) as any;
      name = (decoded as any).name as string;
      console.log('decoded', decoded);
      if (decoded.exp <= Date.now() / 1000) doLogout();
    } catch (e) {
      console.error(e);
      doLogout();
    }
  }

  const getLinks = () => (
    <>
      <NavLink to="/stamps" className={classes.link}>
        All Stamps
      </NavLink>
      <NavLink to="/search" className={classes.link}>
        Search
      </NavLink>
      <NavLink to="/download" className={classes.link}>
        Download
      </NavLink>
      <NavLink to="/faq" className={classes.link}>
        FAQ
      </NavLink>
    </>
  );

  const getButtons = () => (
    <>
      <Button variant="default" component={Link} to="/login">Log in</Button>
      <Button component={Link} to="/register">Register</Button>
    </>
  );

  const getUserSection = () => (
    <Menu position="bottom-end" transition="pop-top-right" onChange={setUserSectionOpened}>
      <Menu.Target>
        <UnstyledButton className={cx(classes.userSection, {[classes.userSectionActive] : userSectionOpened})}>
          <Group spacing={7}>
            {image && <Avatar src={image} radius="xl" alt={name + ' user avatar'} size={20}/>}
            <Text weight={500} size="sm" sx={{lineHeight: 1}} mr={3}>
              {name}
            </Text>
            <IconChevronDown size={12} stroke={1.5}/>
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconArchive size={14} stroke={1.5}/>} component={Link} to="/mystamps">
          My Stamps
        </Menu.Item>
        <Menu.Item icon={<IconLogout size={14} stroke={1.5}/>} onClick={doLogout}>
          Log Out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );

  return (
    <Box sx={{position: 'sticky', top: 0, left: 0, right: 0, zIndex: 99999}}>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: '100%' }}>
          <Link to="/" style={{textDecoration: 'none'}}>
            <img src={DSLogo} height={30} width={30} alt="DocStamper Logo"/>
            <Text span fz="xl" c="blue.5" variant="gradient" fw={650} ml={5} sx={{verticalAlign: 'middle'}}>DocStamper</Text>
          </Link>
          <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
            {getLinks()}
          </Group>

          <Group>
            <Group className={classes.hiddenMobile}>
                {token ? getUserSection() : getButtons()}
            </Group>
            <ActionIcon variant="outline" color={dark ? 'yellow' : 'blue'} onClick={() => toggleColorScheme()} title="Toggle color scheme">
              {dark ? <IconSun size={18}/> : <IconMoonStars size={18}/>}
            </ActionIcon>
            <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
          </Group>
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea sx={{ height: 'calc(100vh - 60px)' }} mx="-md">
          <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
          {getLinks()}
          <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

          <Group position={token ? "left" : "center"} grow={!token} pb="xl" px="md">
            {token ? getUserSection() : getButtons()}
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}