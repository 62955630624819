import { Box, Title, Group, Switch, Button } from "@mantine/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { FormEvent, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import { IconDownload } from "@tabler/icons";
import { downloadFile } from "../utils";

const dateTimeFormat = "YYYY-MM-DD HH:mm";
dayjs.extend(utc);

export default function DownloadStamps() {
  const [start, setStart] = useState<Dayjs | null>(dayjs.utc().subtract(1, 'months').startOf('month'));
  const [end, setEnd] = useState<Dayjs | null>(null);
  const [disabled, setDisabled] = useState(true);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const formRef = useRef<HTMLFormElement>(null);
  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    // @ts-ignore
    downloadFile("/download-range?" + new URLSearchParams(new FormData(formRef.current))); 
    captchaRef.current?.reset();
    setDisabled(true);
  }
  return (
    <>
      <Box
        sx={(theme) => ({
          background: theme.fn.linearGradient(
            190,
            theme.colors.cyan[1],
            theme.colors.cyan[0]
          ),
          padding: "30px 10%",
        })}
      >
        <Title color="dark.4">Download Stamps</Title>
      </Box>
      <Box sx={{width: '80%', margin: '0 auto'}} mt="lg" component="form" onSubmit={handleSubmit} ref={formRef}>
        <p>Download all records in an inclusive date range as a CSV file. (Note: the start date defaults to the first day of the previous month.)</p>
        <Group grow spacing="lg" mt="lg">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={props => <TextField {...props} name="startDateTime"/>} inputFormat={dateTimeFormat}
              label="Start date and time in UTC" value={start} onChange={newVal => setStart(newVal)}/>
            <DateTimePicker
              renderInput={props => <TextField {...props} name="endDateTime"/>} inputFormat={dateTimeFormat}
              label="End date and time in UTC (defaults to now)" value={end} onChange={newVal => setEnd(newVal)}/>
          </LocalizationProvider>
        </Group>
        <Switch size="xl" label="Preserve line breaks in GPG Signatures" my="lg" color="green" sx={{'& .mantine-Switch-track': {alignSelf: 'center', marginRight: 5}}} defaultChecked name="preserveLineBreaks"
        description={
          <>Removing line breaks makes the file friendly for viewing in text editors.<br /> Keeping line breaks makes the file friendly for viewing in Excel and other spreadsheet programs.<br /> (Both work with the provided reference implementation of the auditing tool.)</>
        } onLabel="YES" offLabel="NO"/>
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} onChange={val => setDisabled(!val)} ref={captchaRef}/>
        <Button size="lg" mt="sm" leftIcon={<IconDownload/>} disabled={disabled} type="submit">Download</Button>
      </Box>
    </>
  );
}
